
import { defineAsyncComponent, PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { ClickOutside } from "@/directives";
import { settingsModule } from "@/store";
import {
  ActiveInterface,
  SettingsItem,
  ObjectString,
  PropertiesItem,
  Keys,
} from "@/types";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import {useMq} from "vue3-mq";

const Dropdown = defineAsyncComponent(
  () => import("@/components/ui/BaseDropdown/index.vue")
);

const ListItem = defineAsyncComponent(
  () => import("@/components/ui/BaseList/index.vue")
);

@Options({
  name: "PropertiesBtn",
  props: {
    type: {
      type: String,
    },
    source: {
      type: Array as PropType<SettingsItem[]>,
    },
  },
  directives: {
    "click-outside": ClickOutside,
  },
  components: {
    Dropdown,
    SvgIcon,
    ListItem,
  },
})
export default class PropertiesBtn extends Vue {
  private mq = useMq();
  private isOpened = false;
  private type!: string;

  private get globalLoader(): boolean {
    return settingsModule.getGlobalLoader;
  }

  private get getActive(): ActiveInterface {
    return settingsModule.getActive;
  }

  private get activeItem(): string {
    const active = this.getActive[
      this.type as keyof ActiveInterface
    ] as PropertiesItem;

    return active?.id || "";
  }

  protected get getIconData(): ObjectString {
    const active = this.getActive[
      this.type as keyof ActiveInterface
    ] as PropertiesItem;

    const iconBaseName = this.type === Keys.Type ? this.type : active.name;

    return {
      name: `${iconBaseName}-ico`,
      title: active?.title as string,
    };
  }

  private setActive(obj: SettingsItem): void {
    this.isOpened = false;

    settingsModule.Active({ [this.type]: obj });
  }

  private getListIcon(name: string): string {
    const typeIco = "circle-ico";
    const iconName = this.type === "type" ? typeIco : `${name}-ico`;

    return iconName as string;
  }

  private closeDropdown(): void {
    this.isOpened = false;
  }
}
