import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92075122"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", {
      'btn--large-extra': ['lg', 'xl', 'xxl'].includes(_ctx.mq.current),
      'btn--large': ['xs', 'sm', 'md'].includes(_ctx.mq.current),
      'btn--active': _ctx.isOpened,
    }])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["btn-label", { 'btn-label--active': _ctx.isOpened }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened))
    }, [
      (_ctx.globalLoader)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1))
        : (_openBlock(), _createBlock(_component_SvgIcon, {
            key: 1,
            class: "icon--medium",
            src: `icons/${_ctx.getIconData.name}.svg`,
            alt: _ctx.getIconData.title
          }, null, 8, ["src", "alt"]))
    ], 2),
    _createVNode(_component_dropdown, {
      isOpened: _ctx.isOpened,
      data: _ctx.source,
      activeItem: _ctx.activeItem,
      onOnSelect: _ctx.setActive
    }, {
      item: _withCtx(({item}) => [
        _createVNode(_component_SvgIcon, {
          class: _normalizeClass(["icon--small icon--margin-right", [_ctx.type === 'type' && 'circle', _ctx.type === 'gender' && item.name]]),
          src: `icons/${_ctx.getListIcon(item.name)}.svg`
        }, null, 8, ["class", "src"]),
        _createElementVNode("span", null, _toDisplayString(item.title), 1)
      ]),
      _: 1
    }, 8, ["isOpened", "data", "activeItem", "onOnSelect"])
  ], 2)), [
    [_directive_click_outside, _ctx.closeDropdown]
  ])
}